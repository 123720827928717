@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        font-family: 'ui-sans-serif', 'system-ui', 'BlinkMacSystemFont', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-weight: 400;
    }
}

@layer components {
    .reg-header {
        @apply text-home-header font-semibold mb-8 bg-gradient-to-r from-dim-gray from-40% to-platinum bg-clip-text text-transparent;
    }
}